@import '../../../scss/theme-bootstrap';
/*
.my-account-past-purchases-v1 .product {
  *zoom: 1;
  @include swap_direction(margin,0 0 15px 0);
}

.my-account-past-purchases-v1 .product:before,
.my-account-past-purchases-v1 .product:after {
  content: " ";
  display: table;
}

.my-account-past-purchases-v1 .product:after {
  clear: both;
}

.my-account-past-purchases-v1 .product .product-thumb {
  float: #{$ldirection};
  margin-#{$rdirection}: 5px;
  padding-top: 2px;
}

.my-account-past-purchases-v1 .product .meta {
  font-size: 13px;
  overflow: hidden;
}

.my-account-past-purchases-v1 .product .meta h4.product-title {
  line-height: 15px;
  margin-bottom: 0;
  color: black;
  text-transform: none;
}

.my-account-past-purchases-v1 .product .meta h4.product-title a {
  color: #1f1f1f;
}

.my-account-past-purchases-v1 .product .meta h4.product-title a:hover {
  color: $color-cl-green-2;
}

.my-account-past-purchases-v1 .product .meta span {
  display: block;
  margin-bottom: 2px;
}

.my-account-past-purchases-v1 .product .meta .button {
  margin: 5px 0;
}
*/

.my-account-past-purchases-v1 {
  &.my_account_no_past_purchases > h3 {
    font-size: 36px;
    text-transform: none;
    @include swap_direction(margin, 0 150px 20px 0);
    line-height: 38px;
    vertical-align: bottom;
  }
  .product-col3 {
    max-height: 228px;
    overflow: hidden;
    *zoom: 1;
    @include swap_direction(margin, 0 0 17px 0);
    padding: 0;
  }
}

.my-account-past-purchases-v1 .product-col3:before,
.my-account-past-purchases-v1 .product-col3:after {
  content: ' ';
  display: table;
}

.my-account-past-purchases-v1 .product-col3:after {
  clear: both;
}

.my-account-past-purchases-v1 .product-col3 > li {
  display: block;
  float: #{$ldirection};
  min-height: 228px;
  width: 33%;
  @include swap_direction(padding, 0 5px 0 0);
}

.my-account-past-purchases-v1 .product-col3 > li:last-child {
  padding: 0;
}

.my-account-past-purchases-v1 .my-account-touts {
  overflow: hidden;
}

.my-account-past-purchases-v1 .my-account-touts .my-account-tout {
  float: #{$ldirection};
  margin: 35px 0;
  min-height: 120px;
  @include swap_direction(padding, 0 20px 0 0);
  width: 50%;
}

.my-account-past-purchases-v1 .my-account-touts .my-account-tout.tout-last {
  border-#{$ldirection}: 1px solid #dadada;
  @include swap_direction(padding, 0 0 0 20px);
}
